import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';

const TermsAndCondition = () => {
  const intl = useIntl();

  const isDeLang = () => intl.locale === 'de';

  return (
    <Layout
      section={'payment'}
      bannerTitle={intl.formatMessage({
        id: 'footer_terms_of_use',
        defaultMessage: 'Terms of use',
      })}
    >
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns">
              <div className="column">
                {isDeLang() ? (
                  <div className="content px-6">
                    <p>
                      <strong>1. Geschäftsgegenstand</strong><br></br>
                      <br></br>
                      Vienna Event Tickets ist nicht selbst Veranstalter der angebotenen Veranstaltungen, sondern tritt als Vermittler beziehungsweise Besorger für Eintrittskarten, Wertgutscheine, sowie sonstige Dienstleitungen verschiedener Veranstalter auf.
                      Leistungen im Zusammenhang mit dem Besuch der Veranstaltungen werden ausschließlich vom Veranstalter geschuldet.
                      Unsere Kunden bestätigen mit ihrer Bestellung, dass sie sich über Ort, Art der Veranstaltung, Jugendschutzbestimmungen, Veranstaltungsprogramm sowie Zeit und Dauer der Veranstaltungen informiert haben und diese so akzeptieren.
                      <br></br>Eine Ausnahme stellt der Fall dar, dass Vienna Event Tickets im Einzelfall ausdrücklich selbst Veranstalter ist und dies auch so öffentlich kommuniziert wird. Nach Verfügbarkeit können Kunden über unseren Webshop auch verschiedene Artikel, wie zum Beispiel Merchandising Artikel erwerben.
                      <br></br>
                    </p>
                    <p>
                      <strong>Vertragsabschluss und Zustellung</strong><br></br>
                      <br></br>Ein verbindlicher Vertrag zwischen uns und Ihnen als Kunde kommt erst mit unserer Buchungsbestätigung, welche Sie nach der Durchführung der online Bestellung erhalten, zustande.
                      Im Vorfeld erhalten sie eine Eingangsbestätigung Ihrer online Bestellung, welche nur zur Information dient und keinen verbindlichen Vertrag bedingt.
                      Sollten Sie keine Eingangsbestätigung und in späterer Folge keine Buchungsbestätigung erhalten, informieren Sie uns bitte darüber
                      <br></br>Unsere Kunden akzeptieren eine Zustellung der Buchungsbestätigung per E-Mail, eine individuelle Zustellungsart, wie zum Beispiel Abholung oder Depot beim Veranstaltungsort kann natürlich mit uns individuell vereinbart werden.
                      Vor dem Konzert zeigen Sie dann die Buchungsbestätigung (ausgedruckt oder einfach am Smartphone) an der Abendkassa und erhalten Ihre Eintrittskarten.
                      <br></br>
                    </p>
                    <p>
                      <strong>3. Preise, Gebühren und Umsatzsteuer</strong><br></br>
                      <br></br>Die von uns angegebenen Preise enthalten keine zusätzlichen Gebühren.
                      Eine Ausnahme stellen die Tickets für die Veranstaltungen der Spanischen Hofreitschule dar, auf denen eine Gebühr unsererseits aufgrund unserer Leistungen aufgeschlagen wurde.
                      Alle Preise beinhalten die gesetzliche Umsatzsteuer.
                      <br></br>
                    </p>
                    <p>
                      <strong>4. Storno, Veranstaltungsabsage sowie Stornoschutz</strong><br></br>
                      <br></br>
                      Vienna Event Tickets ist nicht selbst Veranstalter der angebotenen Veranstaltungen.
                      Im Falle einer Veranstaltungsabsage erfolgt die Rückerstattung über uns, wobei hier nur der Kartenpreis des Veranstalters berücksichtigt wird und Vienna Event Tickets keine Haftung für den Ersatz übernimmt.
                      Für den Ersatz kann nur der Veranstalter in Anspruch genommen werden. Kein Ersatz wird für nicht in Anspruch oder nur zu Teilen in Anspruch genommene Karten geleistet. Weiters wird auch kein Ersatz für abhanden gekommene Karten geleistet.
                      Sollte sich die Besetzung, objektiv betrachtet, unwesentlich ändern, akzeptieren unsere Kunden, dass eine Kartenrückgabe aus diesem Grund nicht möglich ist.
                      <br></br>
                      Sie können Ihr Ticket 14 Tage vor dem Tag des Konzertes jederzeit stornieren und bekommen die gesamten Kosten für die Tickets erstattet.
                      Danach wird eine Stornogebühr von 50% bis 48 Stunden vor dem Konzert erhoben. Bei einer Stornierung 48 Stunden vor dem Konzert können leider keine Kosten zurückerstattet werden
                      <br></br>
                      Alle Stornierungen müssen schriftlich an <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a>.
                      <br></br>
                    </p>
                    <p>
                      <strong>5. Rücktrittsrecht</strong><br></br>
                      <br></br> Gemäß §18 Abs 1 Zif 10 FAGG ist das Rücktrittsrecht ausgeschlossen.
                      <br></br>
                    </p>
                    <p>
                      <strong>6. Haftungsausschluss</strong><br></br>
                      <br></br>Wir übernehmen keinerlei Gewähr für die von uns bereitgestellten Informationen.
                      Unsere Kunden verzichten auf Haftungsansprüche gegen uns, die aufgrund unserer Informationen auf unserer Webseite resultieren, auch wenn diese unvollständig oder fehlerhaft sind und auch, wenn sich die Informationen nachträglich geändert haben sollten. Weiters gelten alle Angebote als unverbindlich und jederzeit abänderbar.
                      Teile der Seite oder das gesamte Angebot können unsererseits jederzeit, ohne gesonderte Ankündigung ergänzt, gelöscht oder die Veröffentlichung zeitweise oder endgültig eingestellt werden. Unsere Kunden akzeptieren, dass aufgrund solcher Änderungen keinerlei Haftungsansprüche geltend gemacht werden können.
                      <br></br>
                    </p>
                    <p>
                      <strong>7. Anzuwendendes Recht</strong><br></br>
                      <br></br>Der Gerichtsstand ist Wien und es gilt österreichisches Recht unter Ausschluss des UN Kaufrechts.
                    </p>
                  </div>
                ) : (
                  <div className="content px-6">
                    <p>
                      <strong>1. Business Purpose</strong><br></br>
                      <br></br>
                      Vienna Event Tickets is not the organizer of the offered events, but acts as an agent or procurer of tickets, vouchers, and other services of various organizers.
                      Services in connection with the visit of the events are owed exclusively by the organizer.
                      Our customers confirm with their order that they have informed themselves about the location, type of event, youth protection regulations, event program as well as the time and duration of the events and that they accept them as such.
                      <br />If in a particular case Vienna Event Tickets itself is the organizer of an event, this is communicated publicly.
                      Depending on availability, customers can also purchase various items, such as merchandising items, via our online shop.
                      <br></br>
                    </p>
                    <p>
                      <strong>2. Conclusion of Contract and Delivery</strong><br></br>
                      <br></br>A binding contract between Vienna Event Tickets and its customers comes only with the booking confirmation which our customers will receive after the execution of the online order.
                      After the execution of an online order, our customers will receive a confirmation of receipt which is for information purposes only and does not constitute a binding contract.
                      In case of not receiving a confirmation of receipt and subsequently no booking confirmation, inform Vienna Event Tickets immediately.
                      <br></br>The customers of Vienna Event Tickets accept a delivery of the booking confirmation by e-mail, but an individual delivery method, such as, pick-up or depot at the venue can of course be agreed individually.
                      Before the concert, you have to show the booking confirmation (printed out or simply on your smartphone) at the box office of the concert in order to receive your tickets.
                      <br></br>
                    </p>

                    <p>
                      <strong>3. Prices, Fees, and Sales Tax</strong><br></br>
                      <br></br>All prices indicated do not include any additional fees.
                      An exception are the tickets for the events of the Spanish Riding School, on which a fee was added on our part due to our services.
                      All prices include the statutory sales tax.
                      <br></br>
                    </p>

                    <p>
                      <strong>4. Cancellation, Event Cancellation and Cancellation protection</strong><br></br>
                      <br></br>
                      Vienna Event Tickets is not itself the organizer of the events offered. In case of an event cancellation, the refund will be made through us, whereby only the ticket price of the organizer will be considered and Vienna Event Tickets assumes no liability for the replacement.
                      Only the organizer can be held responsible for the replacement.
                      No replacement will be made for tickets that are not used or only partially used.
                      Furthermore, no replacement will be provided for lost tickets. Should the line-up objectively change insignificantly, our customers accept that a return of tickets for this reason is not possible.
                      <br></br>
                      Still, you can cancel your ticket at any time 14 days before the day of the concert and the entire cost of the tickets will be reimbursed.
                      Thereafter, a cancellation fee of 50% will be charged until 48 hours before the concert.
                      In case of a cancellation 48 hours before the concert, unfortunately, no refund can be made.
                      <br></br>
                      All cancellations must be made in writing to <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a>.
                      <br></br>
                    </p>
                    <p>
                      <strong>5. Right of Withdrawal</strong><br></br>
                      <br></br> According to §18 Abs 1 Zif 10 FAGG the right of withdrawal is excluded.
                      <br></br>
                    </p>
                    <p>
                      <strong>6. Disclaimer</strong><br></br>
                      <br></br>Vienna Event Tickets does not assume any liability for the information provided.
                      Our customers waive liability claims against Vienna Event Tickets resulting from the information presented on our website, even if it is incomplete or incorrect, and even if the information has subsequently changed.
                      Furthermore, all offers are non-binding and subject to change at any time.
                      Parts of the website or the complete publication including all offers and information might be extended, changed, or partly or completely deleted by Vienna Event Tickets without a separate announcement.
                      Our customers accept that no liability claims can be made due to such changes.
                      <br></br>
                    </p>
                    <p>
                      <strong>7. Applicable Law</strong><br></br>
                      <br></br>The place of jurisdiction shall be Vienna and Austrian law shall apply to the exclusion of the UN Convention on Contracts for the International Sale of Goods.
                    </p>
                  </div>
                )}

              </div>
            </div>
          </>
        </div>
      </section>
    </Layout>
  );
};

export default TermsAndCondition;
export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.footer_terms_of_use}
    pathname={location.pathname}
  />
);